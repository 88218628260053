import { useQuery } from '@apollo/client'
import {
  EventCategory,
  Partner,
  Subscription
} from '@local/manage-webhooks-shared/types'
import { useBanquetProps, useSentry } from 'banquet-runtime-modules'

import { DEPOT_GET_WEBHOOKS } from './DEPOT_GET_WEBHOOKS'
import { buildDepotRequestContext } from '../../../environment-switcher-utils'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'

export function useDepotWebhooks() {
  const env = getCurrentEnvironment()
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()
  const { hub } = useSentry()

  const { loading, error, data, refetch } = useQuery<{
    getSubscriptionsForSubscriber: Subscription[]
    getAvailableEventCategoriesForSubscriber: EventCategory[]
  }>(DEPOT_GET_WEBHOOKS, {
    skip: !partner?.guid,
    context: buildDepotRequestContext(partner, mode ?? env),
    onError: (newError) => {
      hub.captureException(newError)
    },
    refetchWritePolicy: 'overwrite'
  })

  refetch({ mode })

  return {
    loading,
    error,
    subscriptions: data?.getSubscriptionsForSubscriber,
    partners: [partner as Partner],
    eventCategories: data?.getAvailableEventCategoriesForSubscriber,
    refetch
  }
}
