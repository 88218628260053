import { useQuery } from '@apollo/client'
import { useBanquetProps, useSentry } from 'banquet-runtime-modules'

import { errorNotFoundOrForbidden } from '../../errorNotFoundOrForbidden'
import { SavedSubscription } from '../../types'

import { DEPOT_GET_SUBSCRIPTION } from './DEPOT_GET_SUBSCRIPTION'
import { buildDepotRequestContext } from '../../../environment-switcher-utils'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { getCurrentEnvironment } from '@toasttab/current-environment'

export function useDepotGetSubscription(guid?: string) {
  const { partner } = useDepotBanquetProps()
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  const { hub } = useSentry()

  const { data, error, loading, refetch } = useQuery<{
    getSubscriptionForSubscriber: SavedSubscription
  }>(DEPOT_GET_SUBSCRIPTION, {
    skip: !guid,
    // fix when user is on subscriptions page, click on 'view events'
    // then click 'edit' and 'delete'
    // user will be redirect back to subscriptions page
    // however user can click go back and still see the edit form
    fetchPolicy: 'no-cache',
    context: buildDepotRequestContext(partner, mode ?? env),
    variables: { guid },
    onError: (newError) => {
      if (!errorNotFoundOrForbidden(newError)) {
        // only send error not 404 or 403 to sentry
        hub.captureException(newError)
      }
    },
    refetchWritePolicy: 'overwrite'
  })

  refetch({ mode })

  return {
    subscription: data?.getSubscriptionForSubscriber,
    error,
    loading
  }
}
