import { useLazyQuery, useMutation } from '@apollo/client'
import { useDomainContext } from '@local/current-domain'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Filter } from '../../types'
import { Event } from '../../types/event.type'
import { RESEND_EVENT } from '../mutations'
import { GET_EVENTS } from '../queries'
import { buildDepotRequestContext } from '../../../environment-switcher-utils'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useBanquetProps } from 'banquet-runtime-modules'
import { getCurrentEnvironment } from '@toasttab/current-environment'

export const useEvents = (
  guid: string,
  filters: Filter,
  pageSize = 10,
  onPaginationReset?: () => void,
  showNewestFirst = true
) => {
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()

  const lastEvaluatedKey = useRef()
  const eventsList = useRef<Array<Event>>([])
  const [events, setEvents] = useState<Array<Event>>([])
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const replayingGuids = useRef<string[]>([])
  const [replaying, setReplaying] = useState<string[]>([])

  const refreshEventsState = () => {
    setEvents(eventsList.current)
    setHasNextPage(lastEvaluatedKey.current !== null)
  }

  const { partner } = useDepotBanquetProps()
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  const { isDepotDomain } = useDomainContext()
  const queryContext = useMemo(() => {
    if (isDepotDomain) {
      return buildDepotRequestContext(partner, mode ?? env)
    }
    return undefined
  }, [isDepotDomain, partner?.guid])

  const [getEvents, { loading: getEventsLoading, error: getEventsError }] =
    useLazyQuery(GET_EVENTS, {
      context: queryContext,
      onCompleted: (data) => {
        const getEvents = data?.getEvents
        if (getEvents) {
          if (Array.isArray(getEvents.webhookEvents)) {
            eventsList.current = [
              ...eventsList.current,
              ...getEvents.webhookEvents
            ]
          }
          lastEvaluatedKey.current = getEvents.lastEvaluatedKey
          refreshEventsState()
        }
      }
    })

  const fetchNextPage = useCallback(() => {
    if (lastEvaluatedKey.current === null) {
      return Promise.resolve()
    }
    return getEvents({
      variables: {
        getEventsInput: {
          pageRequest: {
            pageSize,
            lastEvaluatedKey: lastEvaluatedKey.current || null
          },
          subscriptionGuid: guid,
          startTime: filters.startDateTime?.toISOString() || null,
          endTime: filters.endDateTime?.toISOString() || null,
          status: filters.status,
          scanIndexForward: !showNewestFirst
        }
      }
    })
  }, [getEvents, guid, pageSize, showNewestFirst, filters])

  useEffect(() => {
    eventsList.current = []
    lastEvaluatedKey.current = undefined
    if (onPaginationReset) {
      onPaginationReset()
    }
    fetchNextPage()
  }, [fetchNextPage, onPaginationReset])

  const [resendMutation] = useMutation(RESEND_EVENT, {
    context: queryContext,
    onCompleted: () => {
      if (replaying.length <= 1) {
        showSuccessSnackBar('Webhook event(s) queued for replay', {
          testId: 'replay-event-success-snackbar'
        })
      }
    },
    onError: () => {
      showErrorSnackBar(
        `Something went wrong while attempting to replay that event`,
        { testId: 'replay-event-error-snackbar' }
      )
    }
  })

  const removeGuidFromReplaying = (guid: string) => {
    replayingGuids.current = replayingGuids.current.filter(
      (event) => event !== guid
    )
    refreshReplaying()
  }

  const refreshReplaying = () => {
    setReplaying(replayingGuids.current)
  }

  const resendEvent = (webhookGuid: string, timestamp: string) => {
    replayingGuids.current = [...replayingGuids.current, webhookGuid]
    refreshReplaying()
    resendMutation({
      variables: {
        subscriptionGuid: guid,
        webhookGuid: webhookGuid,
        timestamp: timestamp
      }
    })
      .then(() => removeGuidFromReplaying(webhookGuid))
      .catch(() => removeGuidFromReplaying(webhookGuid))
  }

  return {
    events,
    fetchNextPage,
    hasNextPage,
    getEventsLoading,
    getEventsError,
    resendEvent,
    replaying
  }
}
