import { useWebhooksContext } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  HeadingGroup,
  PageActions,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { Link } from 'react-router-dom'

import { SubscriptionsPage } from '../../../src/components/SubscriptionsPage/SubscriptionsPage'
import { PageWrapper } from '../PageWrapper/PageWrapper'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'

export function DepotSubscriptionsPage() {
  const { partner } = useDepotBanquetProps()
  const { eventCategories } = useWebhooksContext()

  return (
    <PageWrapper>
      <PageHeader>
        <HeadingGroup subTitle={partner?.name}>
          <Title>Webhooks</Title>
        </HeadingGroup>
        {eventCategories && (
          <PageActions>
            <Button
              iconLeft={<AddIcon accessibility='decorative' />}
              as={Link}
              to='/create'
            >
              Add webhook
            </Button>
          </PageActions>
        )}
      </PageHeader>
      <PageBody>
        <SubscriptionsPage />
      </PageBody>
    </PageWrapper>
  )
}
