import { PageWrapper } from '@local/app-routes/PageWrapper/PageWrapper'
import { GuidSubheading } from '@local/events-list/GuidSubheading'
import { SavedSubscription } from '@local/manage-webhooks-shared'
import {
  HeadingGroup,
  PageActions,
  PageBack,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { PropsWithChildren } from 'react'
import { Link, useParams } from 'react-router-dom'

import { CancelButton } from '../CancelButton/CancelButton'

import { DeleteButton } from './DeleteButton'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'

export function EditSubscriptionPageWrapper({
  subscription,
  showSubmitButton,
  disableSubmit,
  isSaveInProgress,
  unsavedChanges,
  children
}: PropsWithChildren<{
  subscription?: SavedSubscription
  showSubmitButton?: boolean
  disableSubmit?: boolean
  isSaveInProgress?: boolean
  unsavedChanges?: boolean
}>) {
  const { partner } = useDepotBanquetProps()
  const { guid } = useParams()

  return (
    <PageWrapper>
      <PageHeader testId='events-page-header'>
        <HeadingGroup subTitle={partner?.name}>
          {guid && <GuidSubheading guid={guid} />}
          <Title>{guid ? 'Edit webhook' : 'Add webhook'}</Title>
        </HeadingGroup>
        <PageActions className='gap-2'>
          {subscription && <DeleteButton />}
          <CancelButton unsavedChanges={unsavedChanges} />
          {showSubmitButton && (
            <SubmitButton
              type='submit'
              disabled={disableSubmit}
              isInProgress={isSaveInProgress}
              testId='submit-button'
            >
              Save
            </SubmitButton>
          )}
        </PageActions>
        <PageBack
          as={Link}
          to='/subscriptions'
          testId='back-to-subscriptions'
        />
      </PageHeader>
      <PageBody>{children}</PageBody>
    </PageWrapper>
  )
}
