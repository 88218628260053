import {
  EventCategory,
  Partner,
  SubscriptionType
} from '@local/manage-webhooks-shared'
import {
  DepotNewSubscriptionInput,
  useDepotCreateSubscription
} from '@local/manage-webhooks-shared/graphql-depot/useDepotCreateSubscription/useDepotCreateSubscription'
import { useBanquetProps } from 'banquet-runtime-modules'
import { FormikContextType, useFormik } from 'formik'

import { useDepotCreateSubscriptionFormSchema } from '../utils/formValidation/formValidation'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { buildDepotRequestContext } from '../../environment-switcher-utils'

export interface DepotCreateSubscriptionFormValues {
  eventCategoryGuid: string
  uri: string
  name: string
  notificationEmail: string
}

const initialValues: DepotCreateSubscriptionFormValues = {
  eventCategoryGuid: '',
  uri: '',
  name: '',
  notificationEmail: ''
}

export function useDepotCreateSubscriptionForm(
  _existingSubscription: undefined,
  _partners: Partner[],
  eventCategories: EventCategory[]
): FormikContextType<DepotCreateSubscriptionFormValues> {
  const { saveSubscription } = useDepotCreateSubscription()
  const { partner } = useDepotBanquetProps()
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  const partnerGuid = partner?.guid as string
  const { auth } = useBanquetProps()
  const userGuid = auth?.userInfo.guid as string

  const validationSchema = useDepotCreateSubscriptionFormSchema(eventCategories)

  return useFormik<DepotCreateSubscriptionFormValues>({
    initialValues,
    onSubmit: async (value) => {
      await saveSubscription({
        variables: {
          newSubscription: getSubscriptionFromFormValues(
            value,
            userGuid,
            partnerGuid
          )
        },
        context: buildDepotRequestContext(partner, mode ?? env)
      })
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema
  })
}

function getSubscriptionFromFormValues(
  formValues: DepotCreateSubscriptionFormValues,
  userGuid: string,
  partnerGuid: string
): DepotNewSubscriptionInput {
  return {
    name: formValues.name,
    subscriberType: SubscriptionType.Partner,
    subscriberGuid: partnerGuid,
    uri: formValues.uri,
    eventCategory: {
      guid: formValues.eventCategoryGuid
    },
    createdBy: userGuid,
    modifiedBy: userGuid,
    notificationEmail: formValues.notificationEmail
  }
}
