import { useLazyQuery } from '@apollo/client'
import { useDomainContext } from '@local/current-domain'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useBanquetProps, useSentry } from 'banquet-runtime-modules'
import { SetStateAction } from 'react'

import { GET_SUBSCRIPTION_SECRET } from './GET_SUBSCRIPTION_SECRET'
import { buildDepotRequestContext } from '../../../environment-switcher-utils'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { getCurrentEnvironment } from '@toasttab/current-environment'

export function useDepotGetSubscriptionSecret(
  guid: string,
  setSecret: (value: SetStateAction<string | undefined>) => void
) {
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()
  const env = getCurrentEnvironment()
  const { isDepotDomain } = useDomainContext()

  const { hub } = useSentry()
  const { showErrorSnackBar } = useSnackBar()

  const [getSecret, { loading: fetchingSecret }] = useLazyQuery(
    GET_SUBSCRIPTION_SECRET,
    {
      context: isDepotDomain
        ? buildDepotRequestContext(partner, mode ?? env)
        : undefined,
      variables: { guid },
      onCompleted: (data) => setSecret(data.getSubscriptionSecret.secret),
      onError: (error) => {
        hub.captureException(error)
        showErrorSnackBar(
          "There was an error fetching this subscription's secret",
          {
            testId: 'secret-error-snackbar'
          }
        )
      }
    }
  )

  return {
    getSecret,
    fetchingSecret
  }
}
