import { useDomainContext } from '@local/current-domain'
import { Page } from '@toasttab/buffet-pui-config-templates'
import { patternGeneralIllustrationDataUrl } from '@toasttab/buffet-pui-illustrations'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'
import { PropsWithChildren } from 'react'
import { envStyle } from '../../environment-switcher-utils'

export function PageWrapper({ children }: PropsWithChildren) {
  const { isDepotDomain } = useDomainContext()
  const env = getCurrentEnvironment()
  const { mode } = useBanquetProps()
  return (
    <Page
      className={isDepotDomain ? `${envStyle(mode ?? env)}` : ''}
      style={
        isDepotDomain
          ? {
              minHeight: 'calc(100vh - 4rem)'
              // backgroundImage: patternGeneralIllustrationDataUrl
            }
          : undefined
      }
    >
      {children}
    </Page>
  )
}
